import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import Img from 'gatsby-image';
import '../template-styles/sponsor-a-baby-party.scss';
import SponsorModal from '../components/sponsorModal';
import LGLForm from '../components/LGLForm';


export default function Template({ data }) {
  const {
    headerImage,
    title,
    description,
    whiteButtonText,
    transparentButtonText,
    transparentButtonLink,
    bigSectionImage,
    bigSectionHeading,
    bigSectionDescription,
    sponsorSectionHeading,
    sponsorSectionDescription,
    sponsorSectionButtonText,
    babyPackage1Image,
    babyPackage1Inclusions,
    babyPackage1Price,
    babyPackage1Title,
    upcomingBabyPartySubheading,
    upcomingBabyPartyText,
    upcomingBabyParties
  } = data.allContentfulPageSponsorABabyParty.edges[0].node;
  const [babyPartyDonationModalShow, setBabyPartyDonationModalShow] = useState(false);
  const {
    heading: sponsorshipHeading,
    description: sponsorshipDesc,
    sponsorship2Img,
    sponsorship2Heading,
    sponsorship2Desc,
    sponsorship2Link,
    sponsorship2LinkText,
    sponsorship3Img,
    sponsorship3Heading,
    sponsorship3Desc,
    sponsorship3Link,
    sponsorship3LinkText
  } = data.allContentfulSectionOtherSponsorships.edges[0].node;

  const renderSponsorButton = (month) => {
    const monthMap = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEPT: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12
    };
  
    const currentMonth = new Date().getMonth() + 2;
    const monthNumber = monthMap[month.toUpperCase()];
  
    if (monthNumber >= currentMonth) {
      return (
        <div className="col-md-3">
          <div className="party-sponsor-btn">
            <a
              className="btn btn-no-href-spec btn-donate btn-sponsor pin-payment-button"
              role="button"
              onClick={(e) => {
                setBabyPartyDonationModalShow(true);
              }}
            >
              Sponsor
            </a>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  const babyPartiesDiv = upcomingBabyParties.map(({ month, iconImage, title }) => (
    <div className="row baby-party-card bottom-border">
      <div className="col-md-2 d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="month-abbr">{month}</div>
          <div>
            <img src={iconImage.file.url} alt="" />
          </div>
        </div>
      </div>
      <div className="col-md-10 d-flex justify-content-between align-items-center">
        <div className="col-md-6">
          <div className="party-details">
            <h4>{title}</h4>
          </div>
        </div>
        {renderSponsorButton(month)}
      </div>
    </div>
  ));
  const babyPartyPackage1 = babyPackage1Inclusions.map(inclusion => <li>{inclusion}</li>);

  return (
    <Layout>
      <SponsorModal id='sponsor-modal2' modalShow={babyPartyDonationModalShow} onHide={() => {
        window.location.hash = "";
        setBabyPartyDonationModalShow(false);
        setTimeout(() => {
          window.location.hash = "sponsorNow";
        }, 200);
      }} title="Sponsor Now" body={babyPartyDonationModalShow && <LGLForm formId={"0HvSmAzkUhgC4fSMQm_AZQ"} initialHeight={767} formPrefix={"https://secure.lglforms.com/form_engine"} />} />
      <HelmetComponent description={description} image={headerImage.fluid.src} title={title} />
      <section id="sponsor-hero">
        <div id="page-sub-header" className="hero">
          {/* first <Img> is the purple overlay */}
          <Img className="w-100 bg-img-overlay position-absolute" fluid={headerImage.fluid} />
          <Img className="w-100 bg-img position-absolute" fluid={headerImage.fluid} />
          <div className="container">
            <div className="page-sub-header-content">
              <div className="row">
                <div className="col-lg-7 col-md-9 col-xs-12">
                  <h1>{title}</h1>
                  <p>{description.description}</p>
                  <div className="row mt-3">
                    <div className="col-md-5 mt-3">
                      <a
                        className="btn btn-no-href btn-reg btn-white mr-5 btn-donate pin-payment-button"
                        role="button"
                        onClick={(e) => {
                          setBabyPartyDonationModalShow(true);
                        }}
                      >
                        {whiteButtonText}
                      </a>
                    </div>
                    <div className="col-md-6 mt-3">
                      <a className="btn-trans" data-toggle="modal" href="#sponsorBabyPartyModal">
                        <i className="material-icons">play_circle_outline</i>
                        {transparentButtonText}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="sponsorBabyPartyModal">
          <div className="modal-dialog">
            <div className="modal-content transparent-modal">
              <div className="modal-header">
                <button aria-hidden="true" className="close" data-dismiss="modal" type="button">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="embed-container">
                  <iframe
                    allowFullScreen
                    data-src={transparentButtonLink}
                    frameBorder="0"
                    id="sponsorBabyPartyVideo"
                    title="Sponsor Baby Party"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-area" id="primary">
        <main className="site-main mt-20" id="main" role="main">
          <section className="two-columns" id="section-sponsor-a-mom">
            <div className="container">
              <div className="two-columns-content row">
                <div className="col-md-6">
                  <Img alt="Sponsor a baby" fluid={bigSectionImage.fluid} />
                </div>
                <div className="col-md-6">
                  <h2>{bigSectionHeading}</h2>
                  <p>{bigSectionDescription.bigSectionDescription}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="one-column" id="section-updates-intro">
            <div className="container">
                <div className="mb46">
                  <img alt="health package" src={babyPackage1Image.file.url} />
                  <h2 className="mt20">
                    <span className="purple">{babyPackage1Title} -</span> {babyPackage1Price}
                  </h2>
                  <ul className="mt40 package-list-2c">{babyPartyPackage1}</ul>
                </div>
            </div>
          </section>

          <section id="upcoming-baby-parties">
            <div className="container">
              <h2>{upcomingBabyPartyText}</h2>
              <p>{upcomingBabyPartySubheading}</p>
            </div>
            <div className="container">{babyPartiesDiv}</div>
          </section>

          <section className="section-cta" id="section-help-a-mom">
            <div className="section-cta-inner">
              <div className="container">
                <div className="section-cta-content row">
                  <div className="col-md-6">
                    <h2>{sponsorSectionHeading}</h2>
                    <p>{sponsorSectionDescription}</p>
                  </div>
                  <div>
                    <a
                      className="btn-reg btn-purple float-right btn-sponsor-baby-party btn-donate"
                      onClick={(e) => {
                        setBabyPartyDonationModalShow(true);
                      }}
                    >
                      {sponsorSectionButtonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>
      <section className="section-other-ways sponsor-other-ways" id="section-other-ways">
        <div className="container">
          <div className="row">
            <div className="col-md-7 text-left mb80">
              <h2>{sponsorshipHeading}</h2>
              <p>{sponsorshipDesc.description}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 cards">
              <div className="sponsor-col-img text-left">
                <Img alt="Sponsor a mom icon" fluid={sponsorship2Img.fluid} />
              </div>
              <h3>{sponsorship2Heading}</h3>
              <p>{sponsorship2Desc.sponsorship2Desc}</p>
              <a className="learnmore-btn" href={sponsorship2Link}>
                {sponsorship2LinkText}
              </a>
            </div>
            <div className="col-md-6 cards">
              <div className="sponsor-col-img text-left">
                <Img alt="Make donations icon" fluid={sponsorship3Img.fluid} />
              </div>
              <h3>{sponsorship3Heading}</h3>
              <p>{sponsorship3Desc.sponsorship3Desc}</p>
              <a className="learnmore-btn" href={sponsorship3Link}>
                {sponsorship3LinkText}
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const sponsorBabyPageQuery = graphql`
  query SponsorBabyPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulPageSponsorABabyParty {
      edges {
        node {
          headerImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          title
          description {
            description
          }
          whiteButtonText
          whiteButtonLink
          transparentButtonText
          transparentButtonLink
          bigSectionImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          bigSectionHeading
          bigSectionDescription {
            bigSectionDescription
          }
          babyPackage1Image {
            file {
              url
            }
          }
          babyPackage1Inclusions
          babyPackage1Price
          babyPackage1Title
          priceSectionText {
            priceSectionText
          }
          babyPartyInclusionImages {
            file {
              url
            }
          }
          babyPartyInclusionText
          upcomingBabyPartySubheading
          upcomingBabyPartyText
          upcomingBabyParties {
            month
            iconImage {
              file {
                url
              }
            }
            title
            donors
            buttonText
            buttonLink
          }
          sponsorSectionHeading
          sponsorSectionDescription
          sponsorSectionButtonText
          sponsorSectionButtonLink
        }
      }
    }
    allContentfulSectionOtherSponsorships {
      edges {
        node {
          heading
          description {
            description
          }
          sponsorship1Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship1Heading
          sponsorship1Desc {
            sponsorship1Desc
          }
          sponsorship1Link
          sponsorship1LinkText
          sponsorship2Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship2Heading
          sponsorship2Desc {
            sponsorship2Desc
          }
          sponsorship2Link
          sponsorship2LinkText
          sponsorship3Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship3Heading
          sponsorship3Desc {
            sponsorship3Desc
          }
          sponsorship3Link
          sponsorship3LinkText
        }
      }
    }
  }
`;
